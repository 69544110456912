export const checkIfHasVariants = (item) => item.sizes || item.textures

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if(checkIfHasVariants(item)){
    return (
      (item.sizes &&
        item.sizes.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected === "")||
        (item.textures &&
          item.textures.length > 0 &&
          selectedVariants &&
          selectedVariants.textureSelected === "") || 
          (item.medals &&
            parseInt(item.medals) > 0 &&
            selectedVariants &&
            selectedVariants.medalSelected === "")
    );
  }
  return checkIfHasVariants(item);
};