
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestProducts: [],
  successfulProducts: ["payload"],
  errorProducts: ["error"],
  requestCategories: [],
  successfulCategories: ["payload"],
  errorCategories: ["error"],
});

export const ProductsActionCreators = Creators;

const initialState = {
  loading: false,
  products: [],
  error: "",
  selectedProduct: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestProducts = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  products: [],
});

//sort by stock
const sortByStock = (a,b) => {
  if (a.stock > b.stock) {
    return 1;
  }
  if (a.stock < b.stock) {
    return -1;
  }
  return 0;
}

const  sortFunc = (a,b) => {
  const { catA } = a.categories && a.categories[0].name;
  const { catB } = b.categories[0].name;
  if(catA !== 'linea-sencillo' && catB === 'linea-sencillo'){
    return -1;
  }
  if(catA === 'linea-sencillo' && catB !== 'linea-sencillo'){
    return 1;
  }
  if(catA === 'linea-sencillo' && catB === 'linea-sencillo'){
    return 1;
  }
  return 0
}

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a.categories[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    if(item.active){
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }}
  });
  let ar = [];
  map.forEach((value, key, map) => {
    if(!value.active){
      ar = ar.concat(value)
    }
  })
  
  return ar.sort((a,b) => sortByStock(a,b));
}

const successfulProducts = (state = initialState, action) => {
  const { message, products } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    products: groupBy(products, pro => pro.categories && pro.categories[0].name).reverse(),
  };
};

const errorProducts = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  products: null,
});

const requestCategories = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  categories: [],
});

const successfulCategories = (state = initialState, action) => {
  const { message, categories } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    categories,
  };
};


const errorCategories = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  categories: null,
});

export default createReducer(initialState, {
  [Types.REQUEST_PRODUCTS]: requestProducts,
  [Types.SUCCESSFUL_PRODUCTS]: successfulProducts,
  [Types.ERROR_PRODUCTS]: errorProducts,
  [Types.REQUEST_CATEGORIES]: requestCategories,
  [Types.SUCCESSFUL_CATEGORIES]: successfulCategories,
  [Types.ERROR_CATEGORIES]: errorCategories,
});