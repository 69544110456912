import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
	requestBlogs: [],
	successfulBlogs: ['payload'],
	errorBlogs: ['error'],
});

export const BlogsActionCreators = Creators;

const initialState = {
	loading: false,
	blogs: [],
	error: '',
	message: ''
};

const requestBlogs = (state = initialState) => ({
	...state,
	loading: true,
	error: '',
	blogs: []
});

const successfulBlogs = (state = initialState, action) => {
	const { message, blogs } = action.payload.result;
	return {
		...state,
		loading: false,
		message,
		blogs
	};
};

const errorBlogs = (state = initialState, action) => ({
	...state,
	loading: false,
	error: action.error,
	blogs: null
});


export default createReducer(initialState, {
	[Types.REQUEST_BLOGS]: requestBlogs,
	[Types.SUCCESSFUL_BLOGS]: successfulBlogs,
	[Types.ERROR_BLOGS]: errorBlogs,
});